import React from "react";
import { useApplication } from "@ryerson/frontend.application";
import { LanguageLocale, NodeLocale } from "@components/Shared/model/LanguageContent";
import { ContentSection } from "@ryerson/frontend.layout";
import { Badge } from "@ryerson/frontend.notification";
import { Link } from "@ryerson/frontend.navigation";
import { Breadcrumb, Crumb } from "@ryerson/frontend.navigation";
import { Typography } from "@ryerson/frontend.typography";
import { Media } from "@ryerson/frontend.layout";
import { useTheme } from "@ryerson/frontend.theme";
import SocialMedia from "@components/NewsEvents/SocialMedia/SocialMedia";
import styled from "@emotion/styled";
import { useStaticQuery, graphql } from "gatsby";
import { renderRichText } from "@components/Shared/model/Contentful";
import { css } from "@emotion/react";
import StayTunedComponent from "@components/Shared/StayTuned/StayTuned";

export type ContenfulNewsNode = {
	body: any;
	category: NewsCategories;
	publishDate: string;
	seoDescription: any;
	seoImage?: any;
	seoKeywords?: string;
	seoTitle?: string;
	slug: string;
	subTitle: any;
	title: string;
	node_locale: NodeLocale;
};

export type StaticNewsContent = {
	latestHeading: string;
};

export type ContentfulNode = {
	node: ContenfulNewsNode;
};

export type NewsProps = {
	contentfulContent: ContentfulNode[];
	content: StaticNewsContent;
};

export type NewsCategories =
	| "Ryerson Earnings"
	| "Earnings Releases"
	| "Ryerson Initiatives"
	| "Ryerson Quarter Results";

export const newsCategories = [
	{
		label: "Ryerson Quarter Results",
		url: "ryerson-quarter-results",
	},
	{
		label: "Earning Calls",
		url: "earning-calls",
	},
	{
		label: "Earnings Releases",
		url: "earnings-releases",
	},
	{
		label: "Ryerson Initiatives",
		url: "ryerson-initiatives",
	},
];

export const TranslateNewsCategory = (selectedCategory: NewsCategories) => {
	let translation: string = "";
	newsCategories.forEach((category) => {
		if (category.label === selectedCategory) {
			translation = category.url;
		}
	});
	return translation;
};

export const MultiLingualNewsCategories: any = {
	en: {
		"ryerson-quarter-results": "Ryerson Quarter Results",
		"earning-calls": "Earning Calls",
		"earnings-releases": "Earnings Releases",
		"ryerson-initiatives": "Ryerson Initiatives",
	},
	fr: {
		"ryerson-quarter-results": "Résultats trimestriels de Ryerson",
		"earning-calls": "Gagner des appels",
		"earnings-releases": "Publications sur les résultats",
		"ryerson-initiatives": "Initiatives Ryerson",
	},
	es: {
		"ryerson-quarter-results": "Resultados del trimestre de Ryerson",
		"earning-calls": "Ganar llamadas",
		"earnings-releases": "Comunicados de resultados",
		"ryerson-initiatives": "Iniciativas Ryerson",
	},
};

export const NewsBadgeColor = (category: NewsCategories) => {
	const { theme } = useTheme();
	const categoryAssociations = {
		"Ryerson Earnings": theme.colors.secondary.green,
		"Earnings Releases": theme.colors.secondary.blue,
		"Ryerson Initiatives": theme.colors.secondary.red,
		"Ryerson Quarter Results": theme.colors.secondary.yellow,
	};
	if (categoryAssociations[category]) return categoryAssociations[category];
	return theme.colors.secondary.purple;
};

const BreadcrumbContainer = styled.div`
	margin-top: 32px;
	${(props: any) => {
		const { theme } = props;
		return css`
			@media only screen and (max-width: ${theme.breakpoints.lg}) {
				margin-top: 0px;
			}
		`;
	}}
`;

const Divider = styled.hr`
	margin-top: 0px;
	margin-bottom: 0px;
	opacity: 0.1;
`;

const Spacer = styled.div`
	display: block;
	width: 100%;
	height: 10px;
`;

const GiantSpacer = styled.div`
	display: block;
	width: 100%;
	height: 270px;
`;

const Left = styled.div`
	display: inline-block;
	width: calc(100% - 300px);
	vertical-align: top;
	padding: 27px 40px 96px 40px;
	box-sizing: border-box;
	height: auto;
	border-radius: 2px;
	margin-top: -310px;
	position: relative;
	margin-bottom: 60px;
	img {
		max-width: 100%;
	}
	p {
		max-width: 660px;
		margin-top: 15px;
		margin-bottom: 15px;
	}
`;

const Right = styled.div`
	display: inline-block;
	width: 290px;
	margin-top: -310px;
	vertical-align: top;
	margin-left: 10px;
`;

const LatestNewsContainer = styled.div`
	display: block;
	width: 100%;
	height: auto;
	padding: 20px 23px;
	box-sizing: border-box;
	margin-bottom: 10px;
	border-radius: 2px;
`;

const Social = styled.div`
	width: 20px;
	height: auto;
	left: -36px;
	position: absolute;
`;

const stayTunedParent = "News-Article";

const News: React.FC<NewsProps> = ({ contentfulContent, content }) => {
	const { theme } = useTheme();
	const {
		localization: { language },
	} = useApplication();

	const FormatDate = (date: any) => {
		let dateObject = new Date(date + " 00:00:00");
		let m: any = dateObject.getMonth() + 1;
		let d: any = dateObject.getDate();
		let y: any = dateObject.getFullYear();
		if (m < 10) m = "0" + m;
		if (d < 10) d = "0" + d;
		return m + "/" + d + "/" + y;
	};

	const [socialReference, setSocialReference] = React.useState<any>(React.createRef());
	const [socialTop, setSocialTop] = React.useState<number>(0);
	React.useEffect(() => {
		if (window !== undefined && document !== undefined) {
			window.addEventListener("scroll", function () {
				if (socialReference.current) {
					let offset = socialReference.current.getBoundingClientRect();
					let startingTop = offset.top - document.body.getBoundingClientRect().top;
					if (
						window.scrollY + 120 >= startingTop &&
						window.scrollY + 265 < socialReference.current.offsetHeight + startingTop
					) {
						setSocialTop(window.scrollY - startingTop + 120);
					} else if (
						socialReference.current.offsetHeight + startingTop <=
						window.scrollY + 265
					) {
						setSocialTop(socialReference.current.offsetHeight - 145);
					} else {
						setSocialTop(0);
					}
				}
			});
		}
		if (!socialReference) setSocialReference(React.createRef());
	}, []);

	const allNewsMeta = useStaticQuery(graphql`
		query AllNewsMetaQuery {
			allContentfulNews(sort: { order: DESC, fields: contentful_id }) {
				nodes {
					title
					slug
					publishDate
					category
					node_locale
					contentful_id
				}
			}
		}
	`);

	let recentNews: any = [];
	let previousId: any = null;
	let i = -1;
	allNewsMeta.allContentfulNews.nodes.forEach((node: any) => {
		if (previousId !== node.contentful_id) {
			i++;
			recentNews[i] = {};
		}
		recentNews[i][node.node_locale.split("-").shift()] = node;
		recentNews[i].date = node.publishDate;
		previousId = node.contentful_id;
	});

	recentNews.sort(function (a: any, b: any) {
		return new Date(b.date).valueOf() - new Date(a.date).valueOf();
	});
	const [languageContent, setLanguageContent] = React.useState<ContenfulNewsNode>(
		contentfulContent[0].node
	);
	React.useEffect(() => {
		contentfulContent.forEach((data: ContentfulNode) => {
			if (LanguageLocale[data.node.node_locale] === language) setLanguageContent(data.node);
		});
	}, [language]);
	const crumbs: Crumb[] = [
		{
			label: "Home",
			url: "/",
		},
		{
			label: "About Us",
			url: "/about-us",
		},
		{
			label: "News & Events",
			url: "/about-us/news-events",
		},
		{
			label: "News",
			url: "/about-us/news-events/news",
		},
	];
	return (
		<>
			<Media greaterThanOrEqual="lg">
				<ContentSection type="tertiary" vPadding="20px">
					<BreadcrumbContainer theme={theme}>
						<Breadcrumb data={crumbs} type="tertiary" size="xs" />
					</BreadcrumbContainer>
					<Typography
						variant="h1"
						css={css`
							margin-top: 20px;
							margin-bottom: 35px;
							max-width: 760px;
							display: block;
						`}
						type="tertiary"
					>
						{languageContent.title}
					</Typography>
					<Badge
						css={css`
							margin-bottom: 45px;
						`}
						type="tertiary"
						color={NewsBadgeColor(languageContent.category)}
					>
						{
							MultiLingualNewsCategories[language][
								TranslateNewsCategory(languageContent.category)
							]
						}
					</Badge>
					<Media lessThan="xl">
						<SocialMedia orientation="horizontal" type="tertiary" />
						<Spacer />
						<Spacer />
					</Media>
					{languageContent.subTitle && languageContent.subTitle.subTitle.length > 0 ? (
						<Typography
							css={css`
								margin-bottom: 64px;
								max-width: 760px;
							`}
							type="tertiary"
							size="lg"
							variant="div"
						>
							{languageContent.subTitle.subTitle}
						</Typography>
					) : (
						<></>
					)}

					<GiantSpacer />
				</ContentSection>
				<ContentSection type="secondary">
					<Left
						ref={socialReference}
						css={css`
							background-color: ${theme.colors.primary.background};
						`}
					>
						<Media greaterThan="lg">
							<Social
								css={css`
									top: ${socialTop + "px"};
								`}
							>
								<SocialMedia orientation="vertical" type="tertiary" />
							</Social>
						</Media>
						<Typography variant="div" type="primary" size="md">
							{renderRichText(
								languageContent.body.raw,
								"primary",
								languageContent.body.references
							)}
						</Typography>
					</Left>
					<Right>
						<LatestNewsContainer
							css={css`
								background-color: ${theme.colors.primary.background};
							`}
						>
							<Typography
								variant="h4"
								type="primary"
								css={css`
									font-size: 20px;
									margin-top: 0px;
									margin-bottom: 20px;
								`}
							>
								{content.latestHeading}
							</Typography>
							<Divider
								css={css`
									margin-top: 0px;
									margin-bottom: 20px;
									color: ${theme.colors.primary.darkerGray};
								`}
							/>
							{recentNews.slice(0, 3).map((node: any, index: number) => {
								return (
									<Link
										to={
											"/about-us/news-events/news/" +
											TranslateNewsCategory(node[language].category) +
											"/" +
											node[language].slug
										}
										key={index}
										gatsby={true}
									>
										<Typography
											variant="div"
											weight="bold"
											css={css`
												margin-bottom: 7px;
											`}
											color={theme.colors.primary.header}
											size="md"
										>
											{node[language].title ?? ""}
										</Typography>
										<Typography
											css={css`
												margin-bottom: 20px;
											`}
											variant="div"
											size="xs"
											color={theme.colors.primary.gray}
										>
											{FormatDate(node[language].publishDate)}
										</Typography>
										{index === recentNews.slice(0, 3).length - 1 ? (
											<></>
										) : (
											<Divider
												css={css`
													margin-top: 0px;
													margin-bottom: 20px;
													color: ${theme.colors.primary.darkerGray};
												`}
											/>
										)}
									</Link>
								);
							})}
						</LatestNewsContainer>
						<StayTunedComponent
							parent={stayTunedParent}
							parentCategory={TranslateNewsCategory(languageContent.category)}
							slim={true}
						/>
					</Right>
					<Divider
						css={css`
							color: ${theme.colors.primary.darkerGray};
						`}
					/>
				</ContentSection>
			</Media>
			<Media lessThan="lg">
				<ContentSection type="tertiary" vPadding="28px">
					<BreadcrumbContainer theme={theme}>
						<Breadcrumb data={crumbs} type="tertiary" size="xs" />
					</BreadcrumbContainer>
					<Typography
						variant="h3"
						css={css`
							margin-top: 17px;
							margin-bottom: 26px;
						`}
						type="tertiary"
					>
						{languageContent.title}
					</Typography>
					<Badge
						css={css`
							margin-bottom: 43px;
						`}
						type="tertiary"
						color={NewsBadgeColor(languageContent.category)}
					>
						{
							MultiLingualNewsCategories[language][
								TranslateNewsCategory(languageContent.category)
							]
						}
					</Badge>
					<SocialMedia orientation="horizontal" type="tertiary" />
				</ContentSection>
				<ContentSection type="primary" vPadding="32px">
					{languageContent.subTitle && languageContent.subTitle.subTitle.length > 0 ? (
						<Typography
							css={css`
								margin-bottom: 32px;
								font-size: 20px;
								margin-top: 0px;
							`}
							type="primary"
							size="xl"
							color={theme.colors.primary.header}
							variant="h3"
						>
							{languageContent.subTitle.subTitle}
						</Typography>
					) : (
						<></>
					)}
					<Typography
						variant="div"
						type="primary"
						css={css`
							margin-bottom: 22px;
							img {
								max-width: 100%;
							}
							p {
								margin-bottom: 20px;
							}
						`}
						size="md"
					>
						{renderRichText(
							languageContent.body.raw,
							"primary",
							languageContent.body.references
						)}
					</Typography>
					<SocialMedia
						color={theme.colors.primary.primaryBrand}
						type="primary"
						orientation="horizontal"
					/>
					<Divider
						css={css`
							margin-top: 8px;
							margin-bottom: 56px;
							color: ${theme.colors.primary.darkerGray};
						`}
					/>

					<Typography
						variant="h4"
						type="primary"
						css={css`
							font-size: 20px;
							margin-top: 0px;
							margin-bottom: 16px;
						`}
					>
						{content.latestHeading}
					</Typography>
					<Divider
						css={css`
							margin-top: 0px;
							margin-bottom: 16px;
							color: ${theme.colors.primary.darkerGray};
						`}
					/>
					{recentNews.slice(0, 3).map((node: any, index: number) => {
						return (
							<Link
								to={
									"/about-us/news-events/news/" +
									TranslateNewsCategory(node[language].category) +
									"/" +
									node[language].slug
								}
								key={index}
								gatsby={true}
							>
								<Typography
									variant="div"
									weight="bold"
									css={css`
										margin-bottom: 7px;
									`}
									color={theme.colors.primary.header}
									size="md"
								>
									{node[language].title ?? ""}
								</Typography>
								<Typography
									css={css`
										margin-bottom: 16px;
									`}
									variant="div"
									size="xs"
									color={theme.colors.primary.gray}
								>
									{FormatDate(node[language].publishDate)}
								</Typography>
								{index === recentNews.slice(0, 3).length - 1 ? (
									<></>
								) : (
									<Divider
										css={css`
											margin-top: 0px;
											margin-bottom: 16px;
											color: ${theme.colors.primary.darkerGray};
										`}
									/>
								)}
							</Link>
						);
					})}
				</ContentSection>
				<StayTunedComponent
					parent={stayTunedParent}
					parentCategory={TranslateNewsCategory(languageContent.category)}
				/>
			</Media>
		</>
	);
};

export default News;
